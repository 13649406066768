import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {axios} from '../exios';
import {API_URL} from '../../packages/keycloak-client/constants';
import {createOptions} from 'shared/helpers/script';

type TCurrencyState = {
  currenciesData: [];
  currenciesMeta: {};
  searchCurrencies: ({setFilters}: any) => void;
  searchCurrenciesScoped: ({setFilters}: any) => void;
  searchAllowedForAnalytic: () => any;
  setCurrencies: ({
                    currenciesData,
                    currenciesMeta,
                  }: TSetCurrenciesProps) => void;
};

type TSetCurrenciesProps = {
  currenciesData: any;
  currenciesMeta: any;
};

export const useCurrencyState = create<TCurrencyState>()(
  devtools(
    (set, get) => ({
      currenciesData: [],
      currenciesMeta: {},
      searchCurrencies: async ({setFilters}: any) => {
        const response = await axios.post(`${API_URL}/currency/search`, {
          limit: 10000,
          page: 1,
        }, {
          headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
          },
        });
        const options = createOptions(response.data.data, 'useCurrencyState');
        localStorage.setItem('currencies', JSON.stringify(options));
        setFilters({options, selectId: 'currencies', isFromRequest: true});
        set(
          produce((draft) => {
            draft.setCurrencies({
              currenciesData: response?.data.data,
              currenciesMeta: response?.data?.meta,
            });
          }),
          false,
          {
            type: 'useCurrencyState => searchCurrencies',
          }
        );
      },
      searchAllowedForAnalytic: async () => {
        try {
          const response = await axios.post(`${API_URL}/currency/search`, {
            scopes: [{name: 'analyticCurrenciesByAccounts'}],
          }, {
            headers: {
              Authorization: axios.defaults.headers.common['Authorization'],
            },
          });
          const options = createOptions(response.data.data, 'useCurrencyState');
          return options;
        } catch {
          return [];
        }
      },
      searchCurrenciesScoped: async ({setFilters}: any) => {
        const response = await axios.post(`${API_URL}/currency/search`, {
          limit: 10000,
          page: 1,
          scopes: [
            {
              name: 'currenciesByAccounts',
            },
          ],
        }, {
          headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
          },
        });
        const options = createOptions(response.data.data, 'useCurrencyState');
        localStorage.setItem('currencies', JSON.stringify(options));
        setFilters({options, selectId: 'currencies', isFromRequest: true});
        set(
          produce((draft) => {
            draft.setCurrencies({
              currenciesData: response?.data?.data,
              currenciesMeta: response?.data?.meta,
            });
          }),
          false,
          {
            type: 'useCurrencyState => searchCurrenciesScoped',
          }
        );
      },
      setCurrencies: ({
                        currenciesData,
                        currenciesMeta,
                      }: TSetCurrenciesProps) => {
        set(
          produce((draft) => {
            draft.currenciesData = currenciesData;
            draft.currenciesMeta = currenciesMeta;
          }),
          false,
          {
            type: 'useCurrencyState => setCurrencies',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useCurrencyState action',
      name: 'currencyState',
    }
  )
);
