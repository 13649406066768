import styled from 'styled-components';
import { Tabs } from '@pankod/refine-antd';

export const AboutAccountWrapper = styled.div`
  width: 100%;

  .ant-tabs-tab {
    padding: 12px 24px !important;
    margin: 20px 0 0 !important;
  }

  .ant-tabs-tab-active {
    color: rgba(22, 119, 255, 1) !important;
    font-weight: 400;
  }

  .ant-tabs-tab-btn {
    color: inherit !important;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-tabs-ink-bar,
  .ant-tabs-ink-bar-animated {
    background-color: rgba(22, 119, 255, 1);
  }

  .ant-table-content table {
    border: none;
    border-top: 1px solid #0000000f;
    border-radius: 0 0 16px 16px;
  }

  .ant-table-wrapper .ant-table-tbody > tr {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 16px !important;
        }

        &:last-child {
          border-radius: 0 0 16px 0;
        }
      }
    }
  }
`;

export const AboutAccountCard = styled.div`
  padding: 24px 0;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.06);
`;

export const HeaderRow = styled.div`
  padding: 0 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  max-width: 75vw;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
`;

export const DescriptionWrapper = styled.div`
  padding: 0 24px;
`;

export const DescriptionItemRow = styled.div<{
  $direction?: 'horizontal' | 'vertical' | undefined;
}>`
  min-width: 350px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  justify-content: start;
  gap: ${(props) => (props.$direction === 'horizontal' ? '10px' : '0')};
  grid-auto-flow: ${(props) =>
    props.$direction === 'horizontal' ? 'row' : 'column'};
  word-break: break-word;
`;

export const DescriptionLabel = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
export const DescriptionValue = styled.span<{
  $direction?: 'horizontal' | 'vertical' | undefined;
}>`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const SpaceBlock = styled.div`
  height: 16px;
`;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 24px;
`;

export const IndexStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-left: 20px;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledDataText = styled.div`
  color: #00000073;
  font-size: 12px;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowGapWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const PaginationWrapper = styled.div`
  margin-top: 20px;
  padding-bottom: 60px;

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
  }
`;

export const UnbreakableWord = styled.div`
  word-break: keep-all;
  white-space: nowrap;
  // margin-right: 16px;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
`;

export const IconsContainerStyled = styled.div`
  display: flex;
  gap: 10px 20px;
  align-items: center;

  span {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  span:hover {
    background-color: rgba(182, 182, 182, 0.18);
  }

  svg {
    zoom: 120%;
  }
`;

export const ModalHeaderStyled = styled.div`
  font-weight: bold;
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
`;

export const ModalDescriptionStyled = styled.div`
  font-size: 13px;
  margin-left: 40px;
`;
