import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IExchangePoint} from '../../interfaces';
import {API_URL} from '../../packages/keycloak-client/constants';
import {axios} from '../exios';
import {useUserState} from '../state/useUserState';
import {useCookies} from 'react-cookie';

interface IInitialState {
    activePoint: IExchangePoint | null;
    exchangePoints: IExchangePoint[];
    setActivePoint: (id: number | string | null) => void;
    refetch: () => void;
    handleExchangePointsCalculator: (idx: number, status: boolean | null) => void;
    calculatorExchangePointStatus: Record<string, boolean> | null;
    setCalculatorExchangePointStatus: Dispatch<
        SetStateAction<Record<string, boolean> | null>
    >;
}

const initialValue: IInitialState = {
    activePoint: null,
    exchangePoints: [],
    setActivePoint: (id: number | string | null) => {
    },
    refetch: () => {
    },
    handleExchangePointsCalculator: (idx: number, status: boolean | null) => {
    },
    calculatorExchangePointStatus: null,
    setCalculatorExchangePointStatus: () => {
    },
};

export const getUserExchangePoints = async ({cookie, setExchangePoints, activePoint, setActivePoint}: any) => {
    try {
        const res: any = await axios.post(
            `${API_URL}/exchange-points/search`,
            {
                limit: 200,
                sort: [
                    {
                        field: 'status',
                        direction: 'desc',
                    },
                    {
                        field: 'name',
                        direction: 'asc',
                    },
                ],
                includes: [
                    {relation: 'selfActiveCurrencies'},
                    {relation: 'currency'},
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}`,
                },
            }
        );
        console.log(
            'setExchangePoints res?.data?.data getUserExchangePoints',
            res?.data?.data,
            res?.data
        );
        const isDataData = !!res?.data?.data
        const isDataDataDataLength = !!res?.data?.data?.data?.length
        
        if (isDataData) {
            setExchangePoints(res?.data?.data);
        }

        if (isDataDataDataLength) {
            if (activePoint?.id !== res?.data?.data?.data[0]?.id) {
                await setActivePoint(res?.data?.data[0]?.id);
            }
        }
    } catch (e) {
        console.debug('editUserProfileOnAPI error', e);
    }
};

const ExchangePointsContext = React.createContext(initialValue);

const ExchangePointsProvider: React.FC<{ children: React.ReactNode }> = ({
                                                                             children,
                                                                         }) => {
    const [cookie, setCookie, removeCookie] = useCookies(['token']);
    const userKC = useUserState((state) => state.userKC);
    const [exchangePoints, setExchangePoints] = useState<IExchangePoint[]>([]);
    const [activePoint, setStateActivePoint] = useState<IExchangePoint | null>(
        null
    );
    const [calculatorExchangePointStatus, setCalculatorExchangePointStatus] =
        useState<Record<string, boolean> | null>(null);

    const setActivePoint = async (id: number | string | null) => {
        if (!id) {
            return;
        }
        localStorage.setItem('activePoint', id!.toString());
        const point = exchangePoints.find((point) => point?.id === Number(id));
        setStateActivePoint(point || null);
    };

    const handleExchangePointsCalculator = (
        idx: number,
        status: boolean | null
    ) => {
        setCalculatorExchangePointStatus((prev) => {
            if (status === null) {
                const obj: Record<string, boolean> = {...prev};
                delete obj[idx];
                //@ts-ignore
                const newObj = Object.assign({}, Object.values(obj)) as Record<
                    string,
                    boolean
                >;
                return newObj;
            }

            if (prev === null) {
                const obj: Record<string, boolean> = {};
                obj[idx] = status;
                return obj;
            } else {
                const obj: Record<string, boolean> = {...prev};
                obj[idx] = status;
                return obj;
            }
        });
    };

    useEffect(() => {
        if (userKC?.profile?.email) {
            localStorage.removeItem('activePoint');
        }
        getUserExchangePoints({cookie, setExchangePoints, activePoint, setActivePoint}).then();
        const interval = setInterval(() => {
            if (!exchangePoints?.length) {
                getUserExchangePoints({cookie, setExchangePoints, activePoint, setActivePoint}).then();
            }
        }, 20000);

        return () => {
            interval && clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const currentExchangePointId = localStorage.getItem('activePoint');
        exchangePoints?.length &&
        setActivePoint(
            currentExchangePointId &&
            exchangePoints.some(
                (point) => String(point.id) === currentExchangePointId
            )
                ? currentExchangePointId
                : exchangePoints[0]?.id
        );
    }, [exchangePoints]);

    return (
        <ExchangePointsContext.Provider
            value={{
                activePoint,
                exchangePoints,
                calculatorExchangePointStatus,
                setActivePoint,
                refetch: () => getUserExchangePoints({cookie, setExchangePoints, activePoint, setActivePoint}),
                handleExchangePointsCalculator,
                setCalculatorExchangePointStatus,
            }}
        >
            {children}
        </ExchangePointsContext.Provider>
    );
};

export {ExchangePointsProvider, ExchangePointsContext};
