import React, { useEffect, useMemo, useState } from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';
import { Typography } from '@pankod/refine-antd';
import {
  Audit,
  BarChart,
  Diagram,
  Document,
  FileDocument,
  Person,
  Room,
  UserGroup,
  Partners,
} from '../../icons';
import { useTranslation } from 'react-i18next';
import { useUserRole } from '../../../hooks/useUserRole';
import {
  StyledMessageOutlined,
  StyledPlaySquareOutlined,
  StyledQuestionCircleOutlined,
} from './_styles';
import { TG_SUPPORT, YT_TEACHING, YT_TEACHING_RU } from '../../../constants';
import { useNavigate } from '@pankod/refine-react-router-v6';
import i18n from 'app/i18n';

type IconComponent =
  | React.ComponentType<
      CustomIconComponentProps | React.SVGProps<SVGSVGElement>
    >
  | React.ForwardRefExoticComponent<CustomIconComponentProps>;

interface IBaseSideElement {
  pathKey: string;
  title: string;
}

interface ISidebarElement {
  pathKey: string;
  iconComponent: (color?: string) => JSX.Element;
  title: string;
  children?: IBaseSideElement[];
  onClick?: () => void;
  disabled?: boolean;
}

interface ISidebarCategories {
  name: string;
  elements: ISidebarElement[];
}

const DrawSideBarMenuElement = (
  title: string,
  icon: IconComponent,
  isSelected: boolean,
  isSubmenu?: boolean,
  hasChild?: boolean
) => (
  <div>
    <Icon component={icon} size={!isSubmenu ? 16 : 10} />
    <Typography.Text
      style={{
        marginInlineStart: 0,
        color: isSelected ? '' : '#FFFFFF',
      }}
    >
      {title}
    </Typography.Text>
  </div>
);

export const useGetSideBarElements = () => {
  const { t } = useTranslation(['faQ', 'shared']);
  const userRole = useUserRole();
  const navigate = useNavigate();

  const [physicalExchangerSidebar, setPhysicalExchangerSidebar] = useState<
    ISidebarElement[]
  >([]);

  const PHYSICAL_EXCHANGER: ISidebarElement[] = useMemo(
    () => [
      {
        pathKey: '/points',
        iconComponent: Room,
        title: t('shared:тексты.Пункты обмена'),
        disabled: false,
      },
      {
        pathKey: '/partners',
        iconComponent: Partners,
        title: t('shared:тексты.Партнёры'),
        disabled: false,
      },
      {
        pathKey: '/clients',
        iconComponent: Person,
        title: t('shared:тексты.Клиенты'),
        disabled: false,
      },
      {
        pathKey: '/accounts',
        iconComponent: FileDocument,
        title: t('shared:тексты.Счета'),
        disabled: false,
      },
      {
        pathKey: '/chains',
        iconComponent: Diagram,
        title: t('shared:тексты.Операции и ДДС'),
        disabled: false,
      },
      {
        pathKey: '/daily-reports',
        iconComponent: Document,
        title: t('shared:тексты.Ежедневные отчёты'),
        disabled: false,
      },
      {
        pathKey: '/analytics',
        iconComponent: BarChart,
        title: t('shared:тексты.Аналитика'),
        disabled: false,
      },
      {
        pathKey: '/staff',
        iconComponent: UserGroup,
        title: t('shared:тексты.Сотрудники'),
        disabled: false,
      },

      {
        pathKey: 'reference',
        iconComponent: Audit,
        title: t('shared:тексты.Справочники'),
        disabled: false,
        children: [
          {
            pathKey: '/currencies',
            title: t('shared:тексты.Валюты'),
            disabled: false,
          },
          {
            pathKey: '/currency-pairs',
            title: t('shared:тексты.Валютные пары'),
            disabled: false,
          },
          {
            pathKey: '/roles',
            title: t('shared:тексты.Роли'),
            disabled: false,
          },
          {
            pathKey: '/mmf',
            title: t('shared:тексты.Типы ДДС'),
            disabled: false,
          },
        ],
      },
    ],
    [t, userRole]
  );

  useEffect(() => {
    if (userRole === 'cashier') {
      setPhysicalExchangerSidebar(
        PHYSICAL_EXCHANGER.filter((element) => {
          if (element.pathKey === '/daily-reports') {
            return false;
          }
          if (element.children) {
            element.children = element.children.filter(
              (child) => child.pathKey !== '/roles'
            );
          }
          if (element.pathKey === '/partners') {
            return false;
          }

          return true;
        })
      );
    } else {
      setPhysicalExchangerSidebar(PHYSICAL_EXCHANGER);
    }
  }, [PHYSICAL_EXCHANGER, userRole]);

  const BOTTOM_SIDEBAR_ELEMENTS: ISidebarElement[] = [
    {
      pathKey: '1',
      title: t('shared:кнопки.Обучающее видео'),
      iconComponent: () => <StyledPlaySquareOutlined />,
      onClick: () => {
        i18n.language === 'ru'
          ? window.open(YT_TEACHING_RU)
          : window.open(YT_TEACHING);
      },
    },
    {
      pathKey: '/questions-answers',
      title: t('Вопросы и ответы'),
      iconComponent: () => <StyledQuestionCircleOutlined />,
      onClick: () => {
        navigate(`/questions-answers`);
      },
    },
    {
      pathKey: '2',
      title: t('shared:кнопки.Тех точка поддержка'),
      iconComponent: () => <StyledMessageOutlined />,
      onClick: () => window.open(TG_SUPPORT),
    },
  ];

  const Physical_exchange_Categories: ISidebarCategories[] = useMemo(() => {
    return [
      {
        name: '',
        elements: physicalExchangerSidebar,
      },
    ];
  }, [physicalExchangerSidebar]);

  return {
    DrawSideBarMenuElement,
    SIDEBAR_CATEGORIES: Physical_exchange_Categories,
    BOTTOM_SIDEBAR_ELEMENTS: BOTTOM_SIDEBAR_ELEMENTS,
  };
};
