import {useMemo, useState} from 'react';
import {CompanyType, MetaType} from '../accountsList/AccountType';
import {useCustom} from '@pankod/refine-core';
import {API_URL} from '../../packages/keycloak-client/constants';
import {CourseType} from './CourseType';
import {CurrencyType} from './CurrencyType';
import {ICurrency} from '../../interfaces';
import {useCookies} from "react-cookie";

const useCurrencyPairs: ({
                           page,
                           sorting,
                           filters,
                           currencyKey,
                         }: {
  sorting?: 'desc' | 'asc' | '';
  page?: number;
  secondaryCurrencyPage?: number;
  secondarySearch: string;
  filters?: any[];
  currencyKey?: string | null;
  secondaryCurrencyKey: string | null;
  showUnusedCurrencies: boolean;
}) => {
  isLoading: false | true;
  isFetching: boolean;
  currencyPairsData: CourseType[];
  isSecondaryCurrencyFetching: boolean;
  isAllSecondaryCurrencyFetched: boolean;
  secondaryCurrenciesData: ICurrency[];
  totalCount: number;
  mainCurrencyKey: string;
  refetchCurrencies: () => void;
  companyCurrency: CurrencyType | null;
} = ({
       page,
       filters,
       currencyKey,
       secondaryCurrencyPage,
       secondarySearch,
       secondaryCurrencyKey,
       showUnusedCurrencies,
     }) => {
  const [cookie, setCookie, removeCookie] = useCookies(['oidc.user', 'token']);
  const [currencyPairsData, setCurrencyPairsData] = useState<CourseType[]>([]);
  const [totalCount, setTotalCount] = useState(10);
  const [secondaryCurrenciesData, setSecondaryCurrenciesData] = useState<
    ICurrency[]
  >([]);
  const [isAllSecondaryCurrencyFetched, setIsAllSecondaryCurrencyFetched] =
    useState(false);
  const [mainCurrencyKey, setMainCurrencyKey] = useState<string>('');
  const [companyCurrency, setCompanyCurrency] = useState<CurrencyType | null>(
    null
  );

  const {isLoading: isMainCourseKeyLoading} = useCustom<{
    data: CompanyType[];
  }>({
    url: `${API_URL}/companies`,
    method: 'get',
    queryOptions: {
      onSuccess: ({data: responseData}) => {
        const response = responseData.data as unknown as CompanyType[];
        setMainCurrencyKey(response[0].currency_key);
      },
    },
  });

  const data = useCustom<{
    data: CurrencyType[];
    meta: MetaType;
  }>({
    url: `${API_URL}/currency/search`,
    method: 'post',
    config: {
      headers: {
        Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}`,
      },
      payload: {
        page: page,
        limit: 10,
        filters: [
          {
            field: 'shortname',
            operator: '=',
            value: mainCurrencyKey,
          },
        ],
      },
    },
    queryOptions: {
      enabled: !!mainCurrencyKey,
      onSuccess: ({data: responseData}) => {
        setCompanyCurrency(responseData.data[0] as unknown as CurrencyType);
      },
    },
  });

  const coursesFilters = useMemo(() => {
    const filters: any[] = [];
    filters.push({
      field: 'primary_currency_key',
      operator: '=',
      value: currencyKey || mainCurrencyKey,
    });
    // !showUnusedCurrencies &&
    //   filters.push(
    //     ...[
    //       {
    //         field: 'primaryCurrency.status',
    //         operator: '=',
    //         value: 'active',
    //       },
    //       {
    //         field: 'secondaryCurrency.status',
    //         operator: '=',
    //         value: 'active',
    //       },
    //     ]
    //   );
    secondaryCurrencyKey &&
    filters.push({
      field: 'secondary_currency_key',
      operator: '=',
      value: secondaryCurrencyKey,
    });
    return filters;
  }, [
    currencyKey,
    mainCurrencyKey,
    showUnusedCurrencies,
    secondaryCurrencyKey,
  ]);
  const {isLoading, refetch, isFetching} = useCustom<{
    data: { data: CourseType[] };
    meta: MetaType;
  }>({
    url: `${API_URL}/courses/search`,
    method: 'post',
    config: {
      payload: {
        page: page,
        limit: 10,
        includes: [{relation: 'secondaryCurrency'}],
        filters: coursesFilters,
        sort: [{field: 'secondary_currency_key', direction: 'asc'}],
      },
    },
    queryOptions: {
      enabled: !!mainCurrencyKey || !!currencyKey,
      onSuccess: ({data: responseData}) => {
        setTotalCount(responseData.meta.total);
        setCurrencyPairsData(responseData.data as unknown as CourseType[]);
      },
    },
  });

  const {isFetching: isSecondaryCurrencyFetching} = useCustom<{
    data: { data: ICurrency[] };
    meta: MetaType;
  }>({
    url: `${API_URL}/currency/search`,
    method: 'post',
    config: {
      headers: {
        Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}`,
      },
      payload: {
        page: secondaryCurrencyPage,
        limit: 20,
        filters: [
          {
            field: 'shortname',
            operator: '!=',
            value: currencyKey || mainCurrencyKey,
          },
        ],
        sort: [{field: 'shortname', direction: 'asc'}],
        search: {
          value: secondarySearch,
          case_sensitive: false,
        },
      },
    },
    queryOptions: {
      enabled: !!mainCurrencyKey || !!currencyKey,
      onSuccess: ({data: responseData}) => {
        if (secondaryCurrencyPage === 1) {
          setSecondaryCurrenciesData(
            responseData.data as unknown as ICurrency[]
          );
        } else {
          setSecondaryCurrenciesData((prev) => [
            ...prev,
            ...(responseData.data as unknown as ICurrency[]),
          ]);
        }
        setIsAllSecondaryCurrencyFetched(
          responseData.meta.total === totalCount
        );
      },
    },
  });

  return {
    isLoading,
    isFetching,
    currencyPairsData,
    totalCount,
    mainCurrencyKey,
    secondaryCurrenciesData,
    isSecondaryCurrencyFetching,
    isAllSecondaryCurrencyFetched,
    refetchCurrencies: refetch,
    companyCurrency,
  };
};

export default useCurrencyPairs;
