import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { jwtDecode } from 'jwt-decode';
import {
  ACCOUNT_URL, ACCOUNT_URL_API,
  API_URL,
  EDENEX_ACCOUNT_URL,
} from 'packages/keycloak-client/constants';
import { axios } from '../exios';
import { useUserState } from './useUserState';
import { useAccessState } from '../../pages/clients/ui/StaffAccessCreationModal/model/useAccessState';
import { useExchangePointState } from './useExchangePointState';
import { COOKIE_CONFIG_DEV, COOKIE_CONFIG_PROD } from '../constants';

type TUserKC = {
  access_token: string | undefined;
  refresh_token: string | undefined;
  auth_time: number;
  profile: {
    email: string;
    email_verified: boolean;
    iat: number;
    sub: string;
  };
};

type TUserAPI = {
  account: {
    email: string;
    status: string;
    userId: number;
    uid: string;
    total: number;
    totalFiat: number;
  };
};

type TGetUserKC = {
  session: any;
  setCookie: any;
};

type TClientCardState = {
  isAuth: boolean;
  isLoading: boolean;
  serverTime: number;
  userKC: TUserKC;
  userAPI: TUserAPI;
  logout: ({ navigate, removeCookie }: TLogout) => void;
  getUserKC: ({ session, setCookie }: TGetUserKC) => Promise<any>;
  getUserProfileFromAPI: (accessToken: string) => Promise<void>;
  refreshToken: ({
    setCookie,
    removeCookie,
    cookie,
    session,
    navigate,
  }: TReloginProps) => Promise<void>;
  getServerTime: (accessToken: string) => Promise<any>;
  setServerTime: (value: number) => void;
  setIsAuth: (value: boolean) => void;
  resendRequest: (config: string, accessToken: string) => Promise<any>;
};

export type TDecodedToken = {
  acr: string;
  aud: string;
  azo: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
};

type TLogout = {
  navigate: any;
  removeCookie: any;
};

type TReloginProps = {
  removeCookie: any;
  setCookie: any;
  cookie: any;
  session: any;
  navigate: any;
  config?: any;
};

export const useAuthState = create<TClientCardState>()(
  devtools(
    (set, get) => ({
      isAuth: false,
      isLoading: false,
      serverTime: 0,
      userKC: {
        access_token: undefined,
        refresh_token: undefined,
        auth_time: 0,
        profile: {
          email: '',
          email_verified: false,
          iat: 0,
          sub: '',
        },
      },
      userAPI: {
        account: {
          email: '',
          status: 'active',
          userId: 0,
          uid: '',
          total: 0,
          totalFiat: 0,
        },
      },
      setIsAuth: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isAuth = value;
          })
        );
      },
      resendRequest: async (config: any, accessToken: string) => {
        // TODO: В процессе доработать функцию переотправки запросов
        if (config.url.includes('exchange-points/search')) {
          console.debug(
            'resendRequest exchange-points/search',
            config.url.includes('exchange-points/search')
          );
          // TODO: getUserExchangePoints()
          useExchangePointState.getState().searchExchangePointsForAccounts();
        }
        if (config.url.includes('user-accounts/search')) {
          const searchParams = new URLSearchParams(window.location.search);
          const pointId = searchParams.get('id');

          console.debug(
            'resendRequest user-accounts/search',
            config.url.includes('user-accounts/search')
          );
          // TODO: useAccessState.getPoints нужен id для отправки запроса
          // TODO: useAccessState.searchExchangePoints
          console.log('resendRequest pointId', pointId);

          if (pointId) {
            // TODO: useExchangePointState.searchSingleExchangePoint
            useExchangePointState
              .getState()
              .searchSingleExchangePoint(parseInt(pointId, 10));
          }

          useAccessState.getState().getPointsStaff();
        }
      },
      refreshToken: async ({
        setCookie,
        cookie,
        session,
        removeCookie,
        navigate,
        config,
      }: TReloginProps) => {
        try {

          if (!session?.refresh_token) return;

          const res = await axios.post(
            `${ACCOUNT_URL_API}/server/edenex-account/api/refresh-token`,
            {
              'refresh_token': session?.refresh_token!.replace('Bearer ', '')
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': ''
              }
            }
          );

          const { access_token, refresh_token } = res?.data?.data;
          const decodedToken: TDecodedToken = jwtDecode(access_token);
          const AccessToken = `Bearer ${access_token?.replace('Bearer ', '')}`;
          const RefreshToken = `${refresh_token?.replace('Bearer ', '')}`;

          const calcOidcUser = () => {
            return JSON.stringify({
              ...session,
              access_token: AccessToken,
              refresh_token: RefreshToken,
            });
          };

          if (config && refresh_token && access_token) {
            setCookie('oidc.user', calcOidcUser(), COOKIE_CONFIG_DEV);
            setCookie('oidc.user', calcOidcUser(), COOKIE_CONFIG_PROD);
            setCookie('email', decodedToken?.email, COOKIE_CONFIG_DEV);
            setCookie('email', decodedToken?.email, COOKIE_CONFIG_PROD);
            setCookie('uid', decodedToken?.sub, COOKIE_CONFIG_DEV);
            setCookie('uid', decodedToken?.sub, COOKIE_CONFIG_PROD);
            setCookie('token', AccessToken, COOKIE_CONFIG_DEV);
            setCookie('token', AccessToken, COOKIE_CONFIG_PROD);
            setCookie('refresh_token', RefreshToken, COOKIE_CONFIG_DEV);
            setCookie('refresh_token', RefreshToken, COOKIE_CONFIG_PROD);

            // @ts-ignore
            axios.defaults.headers['Authorization'] = AccessToken;
            axios.defaults.headers.common.Authorization = AccessToken;

            console.log('refreshToken config', config);
            await get().resendRequest(config, AccessToken);
          }
        } catch (e) {
          console.error('refreshToken error', e);
          useAuthState.getState().logout({ navigate, removeCookie });
        }
      },
      getServerTime: async (accessToken: string) => {
        try {
          const res = await axios.get(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/server-time`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
          const {
            data: { server_time },
          } = res.data;
          set(
            produce((draft) => {
              draft.setServerTime(server_time);
            })
          );
        } catch (e) {
          console.error('getServerTime error', e);
        }
      },
      setServerTime: (value: number) => {
        set(
          produce((draft) => {
            draft.serverTime = value;
          })
        );
      },
      getUserKC: async ({ session, setCookie }: TGetUserKC) => {
        const userKC = session ? session : null;

        if (!userKC || !userKC.access_token) {
          return null;
        }

        const decodedToken: TDecodedToken = jwtDecode(userKC?.access_token);

        setCookie('email', decodedToken?.email, COOKIE_CONFIG_DEV);
        setCookie('email', decodedToken?.email, COOKIE_CONFIG_PROD);
        setCookie('uid', decodedToken?.sub, COOKIE_CONFIG_DEV);
        setCookie('uid', decodedToken?.sub, COOKIE_CONFIG_PROD);
        setCookie(
          'token',
          `${session.access_token?.replace('Bearer ', '')}`,
          COOKIE_CONFIG_DEV
        );
        setCookie(
          'token',
          `${session.access_token?.replace('Bearer ', '')}`,
          COOKIE_CONFIG_PROD
        );
        setCookie(
          'refresh_token',
          `${session.refresh_token}`,
          COOKIE_CONFIG_DEV
        );
        setCookie(
          'refresh_token',
          `${session.refresh_token}`,
          COOKIE_CONFIG_PROD
        );

        const AccessToken = `Bearer ${userKC?.access_token.replace(
          'Bearer ',
          ''
        )}`;

        axios.defaults.headers.common['Authorization'] = AccessToken;
        axios.defaults.headers.common.Authorization = AccessToken;

        useUserState.getState().getUserProfileFromAPIOther(AccessToken);
        await get().getUserProfileFromAPI(AccessToken);

        return userKC;
      },

      logout: ({ navigate, removeCookie }: TLogout) => {
        localStorage.clear();

        removeCookie('oidc.user', COOKIE_CONFIG_DEV);
        removeCookie('oidc.user', COOKIE_CONFIG_PROD);
        removeCookie('remainingTime', COOKIE_CONFIG_DEV);
        removeCookie('remainingTime', COOKIE_CONFIG_PROD);
        removeCookie('refreshTokenTimestamp', COOKIE_CONFIG_DEV);
        removeCookie('refreshTokenTimestamp', COOKIE_CONFIG_PROD);
        removeCookie('currentServerTime', COOKIE_CONFIG_DEV);
        removeCookie('currentServerTime', COOKIE_CONFIG_PROD);
        removeCookie('token', COOKIE_CONFIG_DEV);
        removeCookie('token', COOKIE_CONFIG_PROD);
        removeCookie('refresh_token', COOKIE_CONFIG_DEV);
        removeCookie('refresh_token', COOKIE_CONFIG_PROD);
        removeCookie('email', COOKIE_CONFIG_DEV);
        removeCookie('email', COOKIE_CONFIG_PROD);
        removeCookie('uid', COOKIE_CONFIG_DEV);
        removeCookie('uid', COOKIE_CONFIG_PROD);
        removeCookie('email_verified', COOKIE_CONFIG_DEV);
        removeCookie('email_verified', COOKIE_CONFIG_PROD);
        removeCookie('inviteLink', COOKIE_CONFIG_DEV);
        removeCookie('inviteLink', COOKIE_CONFIG_PROD);
        removeCookie('inviteToken', COOKIE_CONFIG_DEV);
        removeCookie('inviteToken', COOKIE_CONFIG_PROD);
        removeCookie('fromEdenex', COOKIE_CONFIG_DEV);
        removeCookie('fromEdenex', COOKIE_CONFIG_PROD);
        removeCookie('fromFinms', COOKIE_CONFIG_DEV);
        removeCookie('fromFinms', COOKIE_CONFIG_PROD);
        removeCookie('fromPortal', COOKIE_CONFIG_DEV);
        removeCookie('fromPortal', COOKIE_CONFIG_PROD);
        removeCookie('currentPointId', COOKIE_CONFIG_DEV);
        removeCookie('currentPointId', COOKIE_CONFIG_PROD);

        navigate('/home');
      },

      getUserProfileFromAPI: async (accessToken: string) => {
        const response = await axios.get(`${API_URL}/me`, {
          headers: {
            Authorization:
              axios.defaults.headers.common['Authorization'] ||
              `Bearer ${accessToken.replace('Bearer ', '')}`,
          },
        });
        if (!!response) {
          set(
            produce((draft) => {
              draft.userAPI = response.data;
            }),
            false,
            {
              type: 'useAuthStore => getUserProfileFromAPI',
            }
          );
        }
      },
    }),
    {
      anonymousActionType: 'useAuthState action',
      name: 'useClientStateV2',
    }
  )
);
