import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CommonProfileControlIcon,
  ExchangeMapRedirectIcon,
  LogoutIcon,
} from '../../../icons';
import { EditStaffIcon } from 'shared/components/ui/assets/svg/EditStaffIcon';
import { GearKeyclock } from 'shared/components/ui/assets/svg/GearKeyclock';
import NormalDropdown from '../../../ui/dropdown/normalDropdown';
import { UserOutlined } from '@ant-design/icons';
import DropdownRowWithHoverIcon from '../../../ui/dropdown/dropdownRowWithHoverIcon';
import EditCompanyModal from '../modals/EditCompanyModal';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../../../state/useUserState';
import { Button } from '@pankod/refine-antd';
import { useAuthState } from 'shared/state/useAuthState';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useCookies } from 'react-cookie';
import { ACCOUNT_URL, MAP_URL } from 'packages/keycloak-client/constants';
import { COOKIE_CONFIG_DEV, COOKIE_CONFIG_PROD } from '../../../../constants';

const UserDropdown: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const userApi = useUserState((state) => state.userApi);
  const logout = useAuthState((state) => state.logout);

  const [cookie, setCookie, removeCookie] = useCookies([
    'refreshTokenTimestamp',
    'currentServerTime',
    'remainingTime',
    'oidc.user',
    'inviteToken',
    'email',
    'uid',
    'token',
    'refresh_token',
    'isExitUser',
    'fromPortal',
    'currentPointId',
  ]);

  const navigate = useNavigate();
  const handleEdit = () => {
    //setIsCompanyEditModal(true);
    navigate('/company');
  };
  const handleGoToMap = () => {
    window.open(MAP_URL);
  };
  const handleNavigate = () => {
    window.location.href = `${ACCOUNT_URL.replace(
      '/server',
      ''
    )}/server/account/settings?fromMenu=true`;
  };
  const getUserId = () => userApi?.id || localStorage.getItem('userId');
  const userDD = [
    // DropdownRowWithHoverIcon({
    //   key: 'profile',
    //   isOutside: true,
    //   link: '',
    //   iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
    //   text: t('header.userMenu.profile'),
    // }),
    // DropdownRowWithHoverIcon({
    //   key: 'aboutCompany',
    //   isOutside: false,
    //   link: undefined,
    //   iconComp: () => <></>,
    //   text: userApi?.company?.name ?? '',
    //   aboutCompany: true,
    //   description: `${t('shared:тексты.Основная валюта')} ${
    //     userApi?.company?.currency_key
    //   }`,
    // }),
    DropdownRowWithHoverIcon({
      key: 'aboutCompany',
      isOutside: false,
      link: undefined,
      onClickAction: handleEdit,
      iconComp: () => (
        <Button type="ghost" onClick={handleEdit}>
          {EditStaffIcon({
            color: '#000',
            height: 16,
            width: 16,
            fillOpacity: 1,
          })}
        </Button>
      ),
      text: userApi?.company?.name ?? '',
      aboutCompany: true,
      description: `${t('shared:тексты.Основная валюта')} ${
        userApi?.company?.currency_key
      }`,
    }),
    DropdownRowWithHoverIcon({
      key: 'me',
      isOutside: false,
      link: `/staff/${getUserId()}`,
      iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.settings'),
    }),
    DropdownRowWithHoverIcon({
      key: 'keycloack',
      isOutside: false,
      link: undefined,
      onClickAction: handleNavigate,
      iconComp: () =>
        GearKeyclock({
          color: '#000',
          height: 16,
          width: 16,
          fillOpacity: 1,
        }),
      text: t('shared:тексты.Управление профилем'),
    }),
    DropdownRowWithHoverIcon({
      key: 'exit',
      isOutside: false,
      link: ``,
      onClickAction: () => logout({ navigate, removeCookie }),
      iconComp: () => LogoutIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.logout'),
    }),
    DropdownRowWithHoverIcon({
      key: 'goToMap',
      isOutside: false,
      link: undefined,
      onClickAction: handleGoToMap,
      iconComp: () => <EdLogoStyled><ExchangeMapRedirectIcon /> {">"}</EdLogoStyled>,
      text: t('shared:тексты.Веб приложения'),
    }),
  ];
  const adminDD = [
    DropdownRowWithHoverIcon({
      key: 'aboutCompany',
      isOutside: false,
      link: undefined,
      onClickAction: handleEdit,
      iconComp: () => (
        <Button type="ghost" onClick={handleEdit}>
          {EditStaffIcon({
            color: '#000',
            height: 16,
            width: 16,
            fillOpacity: 1,
          })}
        </Button>
      ),
      text: userApi?.company?.name ?? '',
      aboutCompany: true,
      description: `${t('shared:тексты.Основная валюта')} ${
        userApi?.company?.currency_key
      }`,
    }),
    DropdownRowWithHoverIcon({
      key: 'me',
      isOutside: false,
      link: `/staff/${getUserId()}`,
      iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.settings'),
    }),
    DropdownRowWithHoverIcon({
      key: 'keycloack',
      isOutside: false,
      link: undefined,
      onClickAction: handleNavigate,
      iconComp: () =>
        GearKeyclock({
          color: '#000',
          height: 16,
          width: 16,
          fillOpacity: 1,
        }),
      text: t('shared:тексты.Управление профилем'),
    }),
    DropdownRowWithHoverIcon({
      key: 'exit',
      isOutside: false,
      link: '',
      onClickAction: () => logout({ navigate, removeCookie }),
      iconComp: () => LogoutIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.logout'),
    }),
    DropdownRowWithHoverIcon({
      key: 'goToMap',
      isOutside: false,
      link: undefined,
      onClickAction: handleGoToMap,
      iconComp: () => <EdLogoStyled><ExchangeMapRedirectIcon /> {">"}</EdLogoStyled>,
      text: t('shared:тексты.Веб приложения'),
    }),
  ];

  const [userDDItems, setUserDDItems] = useState<any[]>(userDD);
  const [adminDDItems, setAdminDDItems] = useState<any[]>(adminDD);

  const [isCompanyEditModal, setIsCompanyEditModal] = useState(false);

  useEffect(() => {
    setUserDDItems(userDD);
    setAdminDDItems(adminDD);
  }, [t, userApi]);

  return (
    <>
      <NormalDropdown
        isSelectable={false}
        itemWidth={284}
        items={userApi?.role?.name === 'admin' ? adminDDItems : userDDItems}
        align={{ offset: [-40, 4] }}
        content={
          <UserInfoWrapper>
            <UserInfoContainer>
              <Username>{cookie['email'] || userApi?.email}</Username>
              <Role>
                {t(
                  `${
                    userApi?.role?.name === 'admin'
                      ? 'shared:роли.Администратор'
                      : userApi?.role?.name === 'cashier'
                      ? 'shared:роли.Кассир'
                      : userApi?.role?.name === 'manager'
                      ? 'shared:роли.Менеджер'
                      : ''
                  }`
                )}
              </Role>
            </UserInfoContainer>
            <Avatar>
              <UserOutlined
                style={{
                  color: '#FFFFFF',
                  fontSize: 18,
                }}
              />
            </Avatar>
          </UserInfoWrapper>
        }
      />
      {isCompanyEditModal && (
        <EditCompanyModal
          open={isCompanyEditModal}
          close={() => setIsCompanyEditModal(false)}
        />
      )}
    </>
  );
};

export { UserDropdown };

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Avatar = styled.div<{ $mode?: string }>`
  height: 35px;
  width: 35px;
  background: var(--primary-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 7px;
`;

const Role = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
`;

const Username = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 42px;
`;

const EdLogoStyled = styled.div`
display: flex;
gap: 60px;
padding: 5px 0px 20px 0px;
`;