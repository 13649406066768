import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {ConfigProvider, Form, Input, Modal, notification, Row, Select,} from '@pankod/refine-antd';
import {axios} from '../../../../exios';
import {API_URL} from '../../../../../packages/keycloak-client/constants';
import {CurrencyType} from '../../../../../pages/currencyPairs/CurrencyType';
import {DEFAULT_NUMBER, DEFAULT_SEARCH_PAGE_AMOUNT,} from '../../../../helpers/constants';
import {customInfinityScroll} from '../../../../helpers/script';
import {useCustom, useUpdate} from '@pankod/refine-core';
import {CompanyType} from '../../../../../pages/accountsList/AccountType';
import {useTranslation} from 'react-i18next';
import {AdaptiveFullScreenLoader} from '../../../full-screen-loader';
import {uniqValidator} from '../../../../helpers/validators';
import {ThemeConfig} from 'antd';
import {useMainCurrencyExchangePoint} from '../state/useMainCurrencyExchangePoint';
import {useAnalyticsRequestsFiltersState} from '../../../../../pages/analytics/state/useAnalyticsRequestsFiltersState';
import {useAnalyticsAccountBalanceState} from '../../../../../pages/analytics/state/useAnalyticsAccountBalanceState';
import {useAnalyticsActualBenefitState} from '../../../../../pages/analytics/state/useAnalyticsActualBenefitState';
import {useAnalyticsInputState} from '../../../../../pages/analytics/state/useAnalyticsInputState';
import {useAnalyticsOutputState} from '../../../../../pages/analytics/state/useAnalyticsOutputState';
import {useAccountState} from '../../../../state/useAccountState';
import {useClientsState} from '../../../../state/useClientsState';
import {useCurrencyState} from '../../../../state/useCurrencyState';
import {useExchangePointState} from '../../../../state/useExchangePointState';
import {useMmfsState} from '../../../../state/useMmfsState';
import {useOperationsChainTypesState} from '../../../../state/useOperationsChainTypesState';
import {useUserAccountState} from '../../../../state/useUserAccountsState';
import {useLocation} from '@pankod/refine-react-router-v6';
import {useUserState} from 'shared/state/useUserState';
import {useCookies} from "react-cookie";

interface Props {
  open: boolean;
  close: () => void;
}

const EditCompanyModal: FC<Props> = ({open, close}) => {
  const [cookie, setCookie, removeCookie] = useCookies(['oidc.user', 'token']);
  const getUserProfileFromAPIOther = useUserState((state) => state.getUserProfileFromAPIOther);
  const location = useLocation();
  const [isCurrenciesLoading, setIsCurrenciesLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allCurrenciesPage, setAllCurrenciesPage] = useState(DEFAULT_NUMBER);
  const [allCurrencies, setAllCurrencies] = useState<CurrencyType[]>([]);

  const [currencyKey, setCurrencyKey] = useState<string | null>(null);
  const [totalMainCurrencyAmount, setTotalMainCurrencyAmount] = useState(0);
  const [primarySearch, setPrimarySearch] = useState('');
  const [mainCompanyData, setMainCompanyData] = useState<CompanyType | null>(
    null
  );
  const changeMainCurrency = useMainCurrencyExchangePoint(
    (state) => state.changeMainCurrency
  );

  const [companyName, setCompanyName] = useState('');

  const {userApi} = useUserState();

  // const companyId = identity?.data?.companyId;
  const companyId = userApi?.company_id?.toString();

  const {t} = useTranslation(['shared']);

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const {mutate, isLoading} = useUpdate();

  const setFilters = useAnalyticsRequestsFiltersState(
    (state) => state.setFilters
  );

  const searchAnalyticRateBenefit = useAnalyticsActualBenefitState(
    (state) => state.searchAnalyticRateBenefit
  );
  const searchAnalyticArrivals = useAnalyticsInputState(
    (state) => state.searchAnalyticArrivals
  );
  const searchAnalyticExpenditures = useAnalyticsOutputState(
    (state) => state.searchAnalyticExpenditures
  );

  const searchAccounts = useAccountState((state) => state.searchAccounts);
  const searchClients = useClientsState((state) => state.searchClients);
  const searchCurrencies = useCurrencyState(
    (state) => state.searchCurrenciesScoped
  );
  const searchExchangePoints = useExchangePointState(
    (state) => state.searchExchangePoints
  );
  const searchMmfsCodes = useMmfsState((state) => state.searchMmfsCodes);
  const searchOperationsChainTypes = useOperationsChainTypesState(
    (state) => state.searchOperationsChainTypes
  );
  const searchUserAccounts = useUserAccountState(
    (state) => state.searchUserAccounts
  );
  const searchAnalyticsAccountsBalance = useAnalyticsAccountBalanceState(
    (state) => state.searchAnalyticAccountsBalance
  );

  const {isLoading: isMainCompanyDataLoading} = useCustom<{
    data: CompanyType[];
  }>({
    url: `${API_URL}/companies/search`,
    method: 'post',
    config: {
      payload: {
        filters: [
          {
            field: 'id',
            operator: '=',
            value: companyId,
          },
        ],
      },
    },
    queryOptions: {
      enabled: !!companyId && open,
      onSuccess: ({data: responseData}) => {
        const response = responseData.data as unknown as CompanyType[];
        setMainCompanyData(response[0]);
      },
    },
  });

  const onChangeMainCurrency = (value: string) => {
    setPage(1);
    setCurrencyKey(value);
  };

  const fetchAllCurrencies = useMemo(async () => {
    setIsCurrenciesLoading(true);
    if (mainCompanyData?.id) {
      try {
        const filters = primarySearch
          ? []
          : [
            {
              field: 'shortname',
              operator: '!=',
              value: mainCompanyData?.currency_key || '',
            },
          ];

        const response = await axios.post(`${API_URL}/currency/search`, {
          page: allCurrenciesPage,
          limit: 15,
          search: {value: primarySearch, case_sensitive: false},
          filters,
        }, {
          headers: {
            Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}`,
          },
        });
        const currencies = response.data.data.map((item: CurrencyType) => ({
          label: item.shortname,
          value: item.shortname,
          key: item.id,
        }));
        const companyCurrencySelect = {
          label: mainCompanyData?.currency_key,
          value: mainCompanyData?.currency_key,
          key: mainCompanyData?.currency_key,
        };
        if (allCurrenciesPage === DEFAULT_NUMBER) {
          !!primarySearch
            ? setAllCurrencies(currencies)
            : setAllCurrencies([companyCurrencySelect, ...currencies]);
          setTotalMainCurrencyAmount(response.data.meta.total);
        } else {
          setAllCurrencies((prevCurrencies) => [
            ...prevCurrencies,
            ...currencies,
          ]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCurrenciesLoading(false);
      }
    }
  }, [allCurrenciesPage, mainCompanyData, primarySearch]);

  const onMainCurrencyClick = useCallback(async () => {
    !allCurrencies.length && (await fetchAllCurrencies);
  }, [allCurrencies, fetchAllCurrencies]);

  let searchTimer: ReturnType<typeof setTimeout>;
  const onMainCurrencySearch = (value: string) => {
    clearTimeout(searchTimer);
    if (value === '') {
      setAllCurrenciesPage(1);
      setPrimarySearch(value);
      return;
    }
    searchTimer = setTimeout(() => {
      setAllCurrenciesPage(1);
      setPrimarySearch(value);
    }, 1000);
  };

  const clearPrimarySearch = () => {
    setAllCurrenciesPage(1);
    setPrimarySearch('');
  };

  const onMainCurrencyScroll = useCallback(
    async (event: any) => {
      customInfinityScroll(event) &&
      totalMainCurrencyAmount >
      DEFAULT_SEARCH_PAGE_AMOUNT * allCurrenciesPage &&
      setAllCurrenciesPage(allCurrenciesPage + 1);
      if (allCurrenciesPage !== DEFAULT_NUMBER) {
        await fetchAllCurrencies;
      }
    },
    [allCurrenciesPage, fetchAllCurrencies, totalMainCurrencyAmount]
  );

  const changedCompanyName = Form.useWatch<string>('companyName', form);
  const changedCompanyCurrency = Form.useWatch<string>('companyCurrency', form);

  const [hastNoChange, setHasNoChange] = useState(false);
  const [uniqNameError, setUniqNameError] = useState('');

  const clearModal = () => {
    form.setFieldsValue({
      companyName: mainCompanyData?.name,
      companyCurrency: mainCompanyData?.currency_key,
    });
    setUniqNameError('');
    close();
  };

  useEffect(() => {
    if (
      (changedCompanyName &&
        changedCompanyName === mainCompanyData?.name &&
        changedCompanyCurrency &&
        changedCompanyCurrency === mainCompanyData?.currency_key) ||
      !changedCompanyName
    ) {
      setHasNoChange(true);
    } else {
      setHasNoChange(false);
    }
  }, [changedCompanyCurrency, changedCompanyName, mainCompanyData]);

  useEffect(() => {
    form.setFieldsValue({
      companyName: mainCompanyData?.name,
      companyCurrency: mainCompanyData?.currency_key,
    });
  }, [mainCompanyData]);

  const editCompany = async () => {
    form.validateFields().then((values) => {
      const payload = {
        name: values.companyName,
        currency_key: values.companyCurrency,
        id: mainCompanyData?.id,
      };
      mutate(
        {
          dataProviderName: 'physical-exchanger',
          resource: 'companies',
          values: payload,
          id: mainCompanyData?.id || -1,
        },
        {
          onSuccess: (res) => {
            api['success']({
              message: t('shared:статусы.Изменения сохранены'),
              description: t(
                'shared:статусы.Данные компании успешно обновлены точка'
              ),
              style: {borderLeft: '6px solid #52C41A'},
            });
            changeMainCurrency(res.data.data.currency_key);
            const route = location.pathname;
            // TODO: Проверить
            if (route === '/analytics') {
              searchAnalyticsAccountsBalance();
              searchAnalyticRateBenefit();
              searchAnalyticArrivals();
              searchAnalyticExpenditures();
              searchUserAccounts({setFilters});
              searchAccounts({setFilters});
              searchClients({setFilters});
              searchCurrencies({setFilters});
              searchExchangePoints({setFilters});
              searchMmfsCodes({setFilters});
              searchOperationsChainTypes({setFilters});
            }
            getUserProfileFromAPIOther(cookie['token']?.replace('Bearer ', ''));
            clearModal();
          },
          onError: (err) => {
            //todo тут обработка на уникальностьимени
            if (!!err.response.data.errors.name) {
              setUniqNameError(
                String(
                  t('shared:ошибки.Компания с таким названием уже существует')
                )
              );
            }
            getUserProfileFromAPIOther(cookie['token']?.replace('Bearer ', ''));
          },
        }
      );
    });
  };

  const theme: ThemeConfig = {
    components: {
      Select: {
        controlHeight: 32,
      },
      Input: {
        controlHeight: 32,
      },
    },
  };

  return (
    <ConfigProvider theme={theme}>
      <Modal
        title={t('shared:модалки.Изменить данные компании')}
        open={open}
        okText={String(t('shared:кнопки.Сохранить'))}
        width="688px"
        onOk={editCompany}
        okButtonProps={{disabled: hastNoChange}}
        cancelText={String(t('shared:кнопки.Отмена'))}
        onCancel={clearModal}
      >
        {contextHolder}
        <Form form={form} layout="vertical">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Form.Item
              style={{width: '300px'}}
              name="companyName"
              label={t('shared:тексты.Название компании')}
              rules={[
                {
                  required: true,
                  message: String(t('shared:тексты.Введите название компании')),
                },
                {validator: () => uniqValidator(uniqNameError)},
              ]}
            >
              <Input
                maxLength={255}
                value={companyName}
                placeholder={String(
                  t('shared:тексты.Введите название компании')
                )}
                onChange={() => setUniqNameError('')}
              />
            </Form.Item>
            <Form.Item
              style={{width: '300px'}}
              name="companyCurrency"
              label={String(t('shared:тексты.Основная валюта компании'))}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                loading={isCurrenciesLoading}
                onClick={onMainCurrencyClick}
                onChange={onChangeMainCurrency}
                showSearch
                onBlur={clearPrimarySearch}
                onSearch={onMainCurrencySearch}
                filterOption={(input, option) => true}
                onPopupScroll={onMainCurrencyScroll}
                style={{fontWeight: 600}}
                options={allCurrencies}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {isCurrenciesLoading && (
                      <Row style={{padding: '8px 0 2px'}}>
                        <AdaptiveFullScreenLoader/>
                      </Row>
                    )}
                  </>
                )}
              />
            </Form.Item>
          </div>
        </Form>

        <p
          style={{
            marginBottom: 0,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.45)',
          }}
        >
          {t('shared:тексты.После смены основной валюты компании')}
        </p>
      </Modal>
    </ConfigProvider>
  );
};

export default EditCompanyModal;
