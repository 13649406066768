import { IAccount } from '../../ExchangePoints/interface';
import { HeaderRow, HeaderTitle } from './styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

type propsType = {
  accountData: IAccount | null;
};
const Header = ({ accountData }: propsType) => {
  const { t } = useTranslation(['shared']);

  return (
    <HeaderRow>
      <HeaderTitle>
        {(accountData?.shortname && accountData?.shortname) ||
          t('shared:статусы.Нет данных')}
      </HeaderTitle>
    </HeaderRow>
  );
};

export default Header;
