import React, {ReactNode, Suspense} from 'react';
import {Layout as AntdLayout} from 'antd';
import type {RefineLayoutHeaderProps} from '@pankod/refine-ui-types';
import {UserDropdown} from './user-dropdown';
import styled, {css} from 'styled-components';
import {useResize} from '../../../hooks/useResize';
import fb from '../../../../packages/fb/fb';
import {FullScreenLoader} from '../../full-screen-loader';
import {ErrorBoundary} from '../../ErrorBoundary';
import { Banner } from '../../ui/banner/Banner';

const NotificationSelect = React.lazy(
  () => import('./notification-select/NotificationSelect')
);
const LanguageSelect = React.lazy(() => import('./language-select'));
const UserExchangePointsDropdown = React.lazy(
  () => import('./user-exchange-points/index')
);
export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const {isScreen1300} = useResize();

  const userComponent = (): ReactNode => {
    if (!window.location.pathname.includes('payment-logout')) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<FullScreenLoader/>}>
            <UserDropdown />
          </Suspense>
        </ErrorBoundary>
      );
    }
  };

  return (
    <HeaderWrapper
      $mode={!window.location.pathname.includes('payment-logout')}
      $isScreen1300={isScreen1300}
      onClick={() => {
        !isScreen1300 && fb.emit('closeSideBar', true);
      }}
      onTouchStart={() => {
        !isScreen1300 && fb.emit('closeSideBar', true);
      }}
    >
      <HeaderContent>
        <UserExchangePointsDropdown/>
        <HeaderButtons>
          <NotificationSelect/>
          {userComponent()}
          <LanguageSelect mode={'dark'}/>
        </HeaderButtons>
      </HeaderContent>
    </HeaderWrapper>
  );
};

const LoginWrapper = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
`;

const HeaderWrapper = styled(AntdLayout.Header)<{
  $mode: boolean;
  $isScreen1300: boolean;
}>`
  height: 72px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 0;
  ${({$isScreen1300}) =>
    !$isScreen1300 &&
    css`
      margin-left: 80px;
    `}
`;

const HeaderContent = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const HeaderButtons = styled.div`
  height: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
`;
