import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { API_URL } from '../../packages/keycloak-client/constants';
import i18n from 'i18next';
import { axios } from 'shared/exios';
import { TContact } from './contacts/common';

type TUpdateCompanyInfoPayload = {
  name: string;
  currency_key: string;
  country: string | null;
  description: string;
  id?: number | null;
  avatar?: string | null;
};

type TUserState = {
  isBannedUser: boolean | null;
  userApi: {
    company: {
      created_at: string;
      currency: {
        company_id: number;
        created_at: string | null;
        id: number;
        name: string;
        shortname: string;
        status: string;
        type: string;
        updated_at: string | null;
        zeros_number: number;
      };
      currency_key: string;
      id: number;
      name: string;
      updated_at: string;
      country: string | null;
      contacts: TContact[];
      description: string | null;
      avatar: string | null;
    };
    contact_info: any;
    created_at: string;
    updated_at: string;
    firstname: string;
    lastname: string;
    phone_number: string;
    username: string;
    status: string;
    id: number | null;
    role_id: number | null;
    company_id: number | null;
    email: string;
    role: {
      id: number | null;
      description: string;
      name: string;
    };
    uid: string;
  };
  setUserEmail: (email: string) => void;
  setUserApi: (userApi: any) => void;
  userKC: {
    access_token: string | undefined;
    profile: {
      email: string;
    };
  };
  getUserProfileFromAPIOther: (accessToken: string) => void;
  setIsBannedUser: (isBannedUser: boolean) => void;
  setUserRole: (role: string) => void;
  updateUserCompanyInfo: (values: TUpdateCompanyInfoPayload) => Promise<void>;
  getUserCompanyAvatar: () => Promise<any>;
};

export const useUserState = create<TUserState>()(
  devtools(
    (set, get) => ({
      isBannedUser: null,
      userApi: {
        contact_info: null,
        created_at: '',
        updated_at: '',
        firstname: '',
        lastname: '',
        phone_number: '',
        username: '',
        status: 'active',
        id: null,
        role_id: null,
        company_id: null,
        email: '',
        role: {
          id: null,
          description: '',
          name: '',
        },
        uid: '',
        company: {
          created_at: '',
          currency: {
            company_id: 0,
            created_at: null,
            id: 0,
            name: '',
            shortname: '',
            status: '',
            type: '',
            updated_at: null,
            zeros_number: 0,
          },
          currency_key: '',
          id: 0,
          name: '',
          updated_at: '',
          country: null,
          contacts: [],
          description: null,
          avatar: null,
        },
      },
      userKC: {
        access_token: undefined,
        profile: {
          email: '',
        },
      },
      setUserRole: (role: string) => {
        set(
          produce((draft) => {
            draft.userApi.role.name = role;
          })
        );
      },
      setUserEmail: (email: string) => {
        set(
          produce((draft) => {
            draft.userApi.email = email;
            draft.userKC.profile.email = email;
          }),
          true,
          {
            type: 'useUserState => setUserEmail',
          }
        );
      },
      setUserApi: (userApi: any) => {
        set(
          produce((draft) => {
            draft.userApi = userApi;
          }),
          false,
          {
            type: 'useUserState => setUserApi',
          }
        );
      },
      setIsBannedUser: (isBannedUser: boolean) => {
        set(
          produce((draft) => {
            draft.isBannedUser = isBannedUser;
          }),
          true,
          {
            type: 'useUserState => setIsBannedUser',
          }
        );
      },
      getUserCompanyAvatar: async () => {
        try {
          const response = await axios.get(`${API_URL}/me?include=role`);
          set(
            produce((draft) => {
              draft.userApi.company.avatar = response.data[0].company.avatar;
            })
          );
          
          // return response.data.data;
        } catch (error) {
          console.error('getUserCompanyAvatar', error);
        }
      },
      getUserProfileFromAPIOther: async (accessToken: string) => {
        try {
          const res = await axios.get(`${API_URL}/me?include=role`, {
            headers: {
              // @ts-ignore
              Authorization: `Bearer ${accessToken?.replace('Bearer ', '')}`,
            },
          });
          set(
            produce((draft) => {
              const { id, language } = res?.data[0];
              draft.userApi = res?.data[0];
              localStorage.setItem('userId', id);
              localStorage.setItem('companyId', res?.data[0].company_id);
              i18n.changeLanguage(language.toLowerCase() || '').then();
            })
          );
          get().setIsBannedUser(false);
        } catch (e) {
          get().setIsBannedUser(
            //@ts-ignore
            'Access is denied! You are banned.' === e?.message
          );
          console.debug('getUserProfileFromAPIOther error', e);
        }
      },
      updateUserCompanyInfo: async (values: TUpdateCompanyInfoPayload) => {
        let payload: TUpdateCompanyInfoPayload = {
          name: values.name,
          currency_key: values.currency_key,
          country: values.country ?? null,
          description: values.description,
          id: get().userApi.company_id,
        };

        if (values.avatar === null) {
          payload.avatar = null;
        }        
        
        axios
          .put(`${API_URL}/companies/${payload.id}`, payload, {
            headers: {
              Authorization: axios.defaults.headers.common['Authorization'],
            },
          })
          .then((response) => {
            const data = {
              company: {
                name: response.data.data.name,
                currency_key: response.data.data.currency_key,
                country: response.data.data.country || null,
                description: response.data.data.description || null,
                avatar: response.data.data.avatar || null,
                contacts: response.data.data.contacts,
                id: response.data.data.id,
              },
            };

            set(
              produce((draft) => {
                draft.userApi = { ...draft.userApi, ...data };
              })
            );
            axios.post(`${API_URL}/currency/search`, {
              search: { value: values.currency_key, case_sensitive: false }
            }).then((response) => {
              if (response.data.data.length === 0) {
                return;
              }
              const currencyData = {
                company_id: response.data.data[0]?.company_id,
                created_at: response.data.data[0].created_at || null,
                id: response.data.data[0]?.id,
                name: response.data.data[0]?.name,
                shortname: response.data.data[0]?.shortname,
                status: response.data.data[0]?.status,
                type: response.data.data[0]?.type,
                updated_at: response.data.data[0].updated_at || null,
                zeros_number: response.data.data[0]?.zeros_number,
              };
              set(
                produce((draft) => {
                  draft.userApi.company.currency = currencyData;
                })
              );
            })
            .catch((error) => {
              console.error(error);
            });

          })
          .catch((error) => {
            console.error(error);
          });
      },
    }),
    {
      anonymousActionType: 'useUserState action',
      name: 'userState',
    }
  )
);
