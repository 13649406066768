export enum ScreenBreakpoints {
  SCREEN_350 = 350,
  SCREEN_380 = 380,
  SCREEN_480 = 480,
  SCREEN_575 = 575,
  SCREEN_767 = 767,
  SCREEN_991 = 991,
  SCREEN_1024 = 1024,
  SCREEN_1099 = 1099,
  SCREEN_1199 = 1199,
  SCREEN_1300 = 1300,
  SCREEN_1400 = 1400,
  SCREEN_1500 = 1500,
  SCREEN_1600 = 1600,
  SCREEN_1700 = 1700,
}
