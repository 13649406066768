import {default as baseAxios} from 'axios';
import {API_URL} from '../packages/keycloak-client/constants';
import fb from '../packages/fb/fb';
import createAuthRefreshInterceptor from "axios-auth-refresh";

export const axios = baseAxios.create({baseURL: API_URL});

const refreshAuthLogic = (failedRequest: any) => {
    fb.emit('interceptors_error', failedRequest.config)
    return Promise.resolve();
}

const getCookieByName = (name: string): string | null => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const [cookieName, cookieValue] = cookie.split('=');

        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }

    return null;
};

createAuthRefreshInterceptor(
    axios,
    refreshAuthLogic,
    {
        statusCodes: [401, 403],
        retryInstance: axios,
        onRetry: (requestConfig) => ({
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                Authorization: `Bearer ${getCookieByName('token')}`.replace(/Bearer /g, (i => (sub:string) => !i++ ? sub : '')(0)),
            }
        }),
        pauseInstanceWhileRefreshing: true,
        interceptNetworkError: true,
    }
);

axios.interceptors.request.use(
    (config: any) => {
        return config;
    },
    (error: any) => Promise.reject(error)
);
